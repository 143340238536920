import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  currentUser: User;
  constructor(private router: Router) {
    //Check storage for existing user
    const _user = JSON.parse(
      localStorage.getItem('currentUser') ?? '{ "error": true }'
    );
    this.currentUser = new User(_user);
  }
}
