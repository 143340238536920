import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivateComponent } from './public/activate/activate.component';
import { ForgotPwComponent } from './public/forgot-pw/forgot-pw.component';
import { GetAppComponent } from './public/get-app/get-app.component';
import { LoginComponent } from './public/login/login.component';
import { SetPwComponent } from './public/set-pw/set-pw.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'activate/:uuid',
    component: ActivateComponent,
  },
  {
    path: 'qr',
    component: GetAppComponent,
  },
  {
    path: 'private',
    loadChildren: () =>
      import('src/app/private/private.module').then((m) => m.PrivateModule),
  },
  {
    path: 'forgotPw',
    component: ForgotPwComponent,
  },
  {
    path: 'setPw/:uuid',
    component: SetPwComponent,
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
