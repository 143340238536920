export class User
{
  id: string;
  firstName: string;
  lastName: string;
  avatar: string;
  email: string;
  role: UserRole;
  studies: number[];
  studycenter?: number;
  studycenters?: number[];
  activated: boolean;
  lastLogin?: string | null;

  constructor(data?: {
    id: string;
    firstName: string;
    lastName: string;
    avatar: string;
    email: string;
    role: UserRole;
    studies: number[];
    studycenter: number;
    studycenters?: number[];
    activated: boolean;
    lastLogin: string | null;
  })
  {
    this.id = data?.id ?? '';
    this.firstName = data?.firstName ?? '';
    this.lastName = data?.lastName ?? '';
    this.avatar =
      data?.avatar != '' && data?.avatar != undefined
        ? data?.avatar
        : '/assets/avatar.png';
    this.email = data?.email ?? '';
    this.role = data?.role ?? UserRole.sponsor;
    this.studies = data?.studies ?? [];
    this.studycenter = data?.studycenter;
    this.studycenters = data?.studycenters ?? [];
    this.activated = data?.activated ?? false;
    this.lastLogin = data?.lastLogin ?? null;
  }

  getUser()
  {
    return this as UserDTO;
  }

  editUserFromJSON(json: string)
  {
    const _data: User = JSON.parse(json);
    this.firstName = _data?.firstName ?? this.firstName;
    this.lastName = _data?.lastName ?? this.lastName;
    this.email = _data?.email ?? this.email;
    this.role = _data?.role ?? this.role;
  }

  is(role: UserRole)
  {
    return this.role === role;
  }
}
//Begin DTO
export interface UserDTO
{
  id: string;
  firstName: string;
  lastName: string;
  avatar: string;
  email: string;
  role: UserRole;
  studies: number[];
  studycenter: number;
  activated: boolean;
  lastLogin: string | null;
}

export interface UserLoginDTO
{
  email: string;
  password: string;
}

export interface UserActivateDTO
{
  uuid: string;
  password: string;
}

export enum UserRole
{
  admin = 'admin',
  investigator = 'investigator',
  investigatorTeam = 'investigatorTeam',
  monitor = 'monitor',
  sponsor = 'sponsor',
}
