<p-toast></p-toast>
<div class="flex h-full align-items-center justify-content-center mt-8">
    <div class="surface-card p-4 shadow-2 border-round">
        <div class="text-center mb-5">
            <img src="assets/logo.png" alt="Image" height="128" class="m-4">
            <div class="text-900 text-xl font-medium mb-3 font-bold">{{'user.password.reset' | translate}}</div>
        </div>
        <form [formGroup]="pwForm" (ngSubmit)="send()" *ngIf="!success">
            <div class=" field">
                <label for="email">{{'user.email' | translate}}</label>
                <input id="email" type="email" class="p-2 border-1 border-solid surface-border border-round focus:border-primary w-full"
                    formControlName="email">
            </div>
            <button type="submit" pButton label="Send" class="p-2 w-full" [disabled]="pwForm.invalid"></button>
            <button type="submit" pButton pRipple [label]="'general.back' | translate" class="w-full mt-4 p-button-text" routerLink="/"></button>
            <p-menubar [model]="footerNavItems" styleClass="footer mt-4" [style]="{'flex-direction': 'column', 'min-width': '250px'}"></p-menubar>
        </form>
        <div *ngIf="success" class="text-center">
            {{'user.password.reset_mail' | translate}}
        </div>
    </div>
</div>
