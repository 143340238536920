import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-forgot-pw',
  templateUrl: './forgot-pw.component.html',
  styleUrls: ['./forgot-pw.component.scss']
})
export class ForgotPwComponent implements OnInit
{
  pwForm: UntypedFormGroup;
  success: boolean = false;
  footerNavItems: MenuItem[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private msg: MessageService,
    private api: ApiService,
    private translate: TranslateService
  )
  {
    this.pwForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]]
    })
  }

  async ngOnInit()
  {
    this.translate.get(["title.imprint", "title.privacy"]).subscribe(_translations =>
    {
      this.footerNavItems = [
        {
          label: _translations["title.imprint"],
          url: "https://clincompetence.de/en/legal-notice/",
          target: "_blank",
        },
        {
          label: _translations["title.privacy"],
          url: "https://clincompetence.de/en/privacy-policy/",
          target: "_blank"
        }
      ];
    });
  }

  send()
  {
    this.api.forgotPw(this.pwForm.value).subscribe({
      next: (res) =>
      {
        if (res.success)
        {
          this.msg.add({ summary: "Success", detail: 'You will receive an email soon.', severity: 'success' });
          this.success = true;
        }
        else
        {
          this.msg.add({ summary: "Error", detail: 'An error occurred during the process.', severity: 'error' });
        }
      },
      error: (err) =>
      {
        this.msg.add({ summary: "Error", detail: 'An error occurred during the process.', severity: 'error' });
      }
    })
  }
}
