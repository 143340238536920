import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MenuItem, MessageService } from 'primeng/api';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user';
import { firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  footerNavItems: MenuItem[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private api: ApiService,
    private router: Router,
    public msg: MessageService,
    private userService: UserService,
    private translate: TranslateService,
    private jwt: JwtHelperService
  ) {
    this.loginForm = fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    const _token = this.jwt.tokenGetter();
    if (_token != null) {
      this.router.navigate(['/private'], { replaceUrl: true });
      return;
    }

    this.translate
      .get(['title.imprint', 'title.privacy'])
      .subscribe((_translations) => {
        this.footerNavItems = [
          {
            label: _translations['title.imprint'],
            url: 'https://clincompetence.de/en/legal-notice/',
            target: '_blank',
          },
          {
            label: _translations['title.privacy'],
            url: 'https://clincompetence.de/en/privacy-policy/',
            target: '_blank',
          },
        ];
      });
  }

  login() {
    if (this.loginForm.valid) {
      this.api.login(this.loginForm.value).subscribe({
        next: async (res) => {
          if (res.success && res.access_token) {
            //Set JWT to storage
            localStorage.setItem('auth-token', res.access_token);
            //Set user as currentUser

            this.userService.currentUser = new User(
              await firstValueFrom(this.api.getUser(res.uid))
            );
            //Save currentUser
            localStorage.setItem(
              'currentUser',
              JSON.stringify(this.userService.currentUser.getUser())
            );
            //Mark as logged in
            this.router.navigateByUrl('/private');
            this.msg.add({
              summary: 'Success',
              detail: 'You have been successfully logged in.',
              severity: 'success',
            });
          } else {
            this.msg.add({
              summary: 'Error',
              detail: 'An error occurred during the process.',
              severity: 'error',
            });
          }
        },
        error: (err) => {
          this.msg.add({
            summary: 'Error',
            detail: 'An error occurred during the process.',
            severity: 'error',
          });
        },
      });
    }
  }
}
