<div class="content-box">
    <div class="text-center">
        <img src="assets/logo.png" alt="Image" height="128">
        <!-- <h1>Erhalte die App!</h1> -->
    </div>

    <div class="badge-box">
        <a href="https://play.google.com/store/apps/details?id=com.ClinCompetenceCologne.studydiary"><img
                src="/assets/de_badge_web_generic.png"></a>
        <a href="https://apps.apple.com/de/app/ccc-study-diary/id6443583026"><img src="/assets/apple-appstore-badge.png"
                style="transform: scale(1.13)"></a>
        <a href="/assets/builds/StudyDiary.apk"><img src="/assets/de_badge_web_huawei.png"></a>
    </div>
</div>