<p-toast></p-toast>
<div class="flex h-full align-items-center justify-content-center mt-8">
    <div class="surface-card p-4 shadow-2 border-round">
        <div class="text-center mb-5">
            <img src="assets/logo.png" alt="Image" height="128" class="m-4">
            <div class="text-900 text-3xl font-medium mb-3 font-bold">{{'title.login' | translate}}</div>
        </div>
        <form [formGroup]="loginForm" (ngSubmit)="login()">

            <div class="p-fluid">
                <label for="email" class="block text-900 font-medium mb-2">{{'user.email' | translate}}</label>
                <input id="email" pInputText type="email" class="w-full mb-3" formControlName="email" />

                <label for="password" class="block text-900 font-medium mb-2">{{'user.password.text' | translate}}</label>
                <p-password id="password" [feedback]="false" formControlName="password" [toggleMask]="true">
                </p-password>
            </div>

            <div class="flex align-items-center justify-content-between mb-6">
                <a class="mt-2 font-medium no-underline text-blue-500 text-right cursor-pointer"
                    routerLink="/forgotPw">{{'user.password.forgot' | translate}}</a>
            </div>

            <button type="submit" pButton [label]="'title.login' | translate" class="w-full" [disabled]="loginForm.invalid"></button>
            <p-menubar [model]="footerNavItems" styleClass="footer mt-4" [style]="{'flex-direction': 'column', 'min-width': '250px'}"></p-menubar>
        </form>
    </div>
</div>
