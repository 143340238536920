<p-toast></p-toast>
<div class="flex h-full align-items-center justify-content-center mt-8">
    <div class="surface-card p-4 shadow-2 border-round">
        <div class="text-center mb-5">
            <img src="assets/logo.png" alt="Image" height="128" class="m-4">
            <div class="text-900 text-xl font-medium mb-3 font-bold">To activate your user account,<br> please assign a
                password.</div>
            <!-- <div class="text-500 text-3xl font-medium mb-3">Bitte vergeben sie ein Passwort</div> -->
        </div>
        <form [formGroup]="activateForm" (ngSubmit)="activate()">
            <div class="field w-full mb-2">
                <label for="password" class="block">Password</label>
                <p-password id="password" formControlName="password" [toggleMask]="true" styleClass="p-input-icon-right"
                    weakLabel="Bad" mediumLabel="Good" strongLabel="Perfect" class="w-full">
                    <ng-template pTemplate="header">
                        <h6>Strength of password</h6>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <p class="mt-2">Requirements</p>
                        <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                            <li>Minimum 8 characters</li>
                            <li>Minimum one capital letter</li>
                            <li>Minimum one lower case letter</li>
                            <li>Minimum one number</li>
                        </ul>
                    </ng-template>
                </p-password>

            </div>
            <button type="submit" pButton label="Activate account" class="p-2 w-full"
                [disabled]="activateForm.invalid"></button>
        </form>
    </div>
</div>