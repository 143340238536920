import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { APIResult, APIResultLogin } from '../models/api';
import { PatientDTO } from '../models/patient';
import { StudyDTO, StudyListDTO } from '../models/study';
import { StudyCenterDTO } from '../models/studyCenter';
import { UserActivateDTO, UserDTO, UserLoginDTO } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  //Auth routes
  login(data: UserLoginDTO) {
    return this.http.post<APIResultLogin>(
      `${environment.apiUrl}/auth/login/cms`,
      data
    );
  }

  forgotPw(email: string) {
    return this.http.post<APIResult>(
      `${environment.apiUrl}/auth/forgotPw`,
      email
    );
  }

  //User routes
  activateUser(data: UserActivateDTO) {
    return this.http.post<APIResult>(
      `${environment.apiUrl}/auth/activate`,
      data
    );
  }

  editUser(data: UserDTO, mode?: string) {
    if (mode != 'own') mode = '';
    return this.http.patch<APIResult>(
      `${environment.apiUrl}/users/${data.id}`,
      { ...data, mode }
    );
  }

  createUser(data: UserDTO) {
    return this.http.post<APIResult>(`${environment.apiUrl}/users`, data);
  }

  getUsers() {
    return this.http.get<UserDTO[]>(`${environment.apiUrl}/users`);
  }

  getUser(id: string) {
    return this.http.get<UserDTO>(`${environment.apiUrl}/users/${id}`);
  }

  deleteUser(id: string) {
    return this.http.delete<APIResult>(`${environment.apiUrl}/users/${id}`);
  }

  resendActivation(id: string) {
    return this.http.post<APIResult>(
      `${environment.apiUrl}/auth/resendActivation`,
      { id }
    );
  }

  //Studies routes
  getAllStudies() {
    return this.http.get<StudyDTO[]>(`${environment.apiUrl}/studiesList`);
  }

  getStudies(data: UserDTO) {
    return this.http.get<StudyListDTO[]>(
      `${environment.apiUrl}/users/${data.id}/studies`
    );
  }

  getStudy(id: string) {
    return this.http.get<StudyDTO>(`${environment.apiUrl}/studies/${id}`);
  }

  createStudy(data: StudyDTO) {
    return this.http.post<APIResult>(`${environment.apiUrl}/studies`, data);
  }

  editStudy(data: StudyDTO) {
    return this.http.patch<APIResult>(`${environment.apiUrl}/studies`, data);
  }

  getSurveys(studyId: number) {
    return this.http.get<any[]>(
      `${environment.apiUrl}/studies/${studyId}/surveys`
    );
  }

  getSurvey(surveyId: number) {
    return this.http.get<any[]>(`${environment.apiUrl}/survey/${surveyId}`);
  }

  //StudyCenters Route
  getAllStudyCenters() {
    return this.http.get<StudyCenterDTO[]>(`${environment.apiUrl}/centers`);
  }

  getStudyCenters(id: string) {
    return this.http.get<StudyCenterDTO[]>(
      `${environment.apiUrl}/studies/${id}/centers`
    );
  }

  getStudyCenter(id: string) {
    return this.http.get<StudyCenterDTO>(`${environment.apiUrl}/centers/${id}`);
  }

  createStudyCenter(data: StudyCenterDTO) {
    return this.http.post<APIResult>(`${environment.apiUrl}/centers`, data);
  }

  editStudyCenter(data: StudyCenterDTO) {
    return this.http.patch<APIResult>(`${environment.apiUrl}/centers`, data);
  }

  //Patients routes
  getPatients(studyId: string, centerId: string) {
    //Build query Parameter
    let queryParams = new HttpParams();
    queryParams = queryParams.append('study', studyId);
    queryParams = queryParams.append('center', centerId);
    return this.http.get<PatientDTO[]>(`${environment.apiUrl}/patients`, {
      params: queryParams,
    });
  }

  getPatient(id: string | number) {
    return this.http.get<PatientDTO>(`${environment.apiUrl}/patients/${id}`);
  }

  patchPatient(id: number, data: { active: boolean }) {
    return this.http.patch<APIResult>(
      `${environment.apiUrl}/patients/${id}`,
      data
    );
  }

  //VISITATIONS
  getVisitations(studyId: number, centerId: number, patientId: number) {
    return this.http.get<string[]>(
      `${environment.apiUrl}/patients/${patientId}/visitations`,
      {
        params: {
          studyId,
          centerId,
        },
      }
    );
  }

  postVisitation(
    studyId: number,
    centerId: number,
    patientId: number,
    visitationDate: string
  ) {
    return this.http.post<string[]>(
      `${environment.apiUrl}/patients/${patientId}/visitations`,
      { visitationDate },
      {
        params: {
          studyId,
          centerId,
        },
      }
    );
  }

  getPatientData(
    studyId: number,
    centerId: number,
    patientId: number,
    data: any
  ) {
    return this.http.post<any[]>(
      `${environment.apiUrl}/patients/${patientId}/data`,
      data,
      {
        params: {
          studyId,
          centerId,
        },
      }
    );
  }

  //INJECTIONS
  getInjections(studyId: number, surveyId: number, patientId: number) {
    return this.http.get<string[]>(
      `${environment.apiUrl}/patients/${patientId}/injections`,
      {
        params: { studyId, surveyId },
      }
    );
  }

  postInjection(
    studyId: number,
    centerId: number,
    patientId: number,
    data: any
  ) {
    return this.http.post(
      `${environment.apiUrl}/patients/${patientId}/injections`,
      data,
      {
        params: { studyId, centerId },
      }
    );
  }
}
