import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-set-pw',
  templateUrl: './set-pw.component.html',
  styleUrls: ['./set-pw.component.scss']
})
export class SetPwComponent implements OnInit {
  activateForm: UntypedFormGroup

  constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute, private api: ApiService, private msg: MessageService, private router: Router) 
  { 
    this.activateForm = this.fb.group({
      uuid: ["", Validators.required],
      password: ["", [Validators.required, Validators.pattern('(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}')]]
    })
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(async params => {
      if (!params.has('uuid')) return;
      this.activateForm.get('uuid')?.patchValue(params.get('uuid'));
    })
  }

  activate()
  {
    this.api.activateUser(this.activateForm.value).subscribe(
      {
      next: (res) => {
        if (res.success)
        {
          this.msg.add({ summary: "Success", detail: 'Your password has been successfully renewed', severity: 'success'});
          this.router.navigate(["login"]);
        }
        else
        {
          this.msg.add({ summary: "Error", detail: 'An error occurred during the process.', severity: 'error'});
        }
      },
      error: (err) => {
        this.msg.add({ summary: "Error", detail: 'An error occurred during the process.', severity: 'error'});
      }
    });
  }
}
